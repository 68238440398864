@import "src/styles/references/index";

.ns-drawer.headerless {
    .ant-drawer-header {
        border-bottom: unset !important;
    }
}
.ant-drawer-header-title {
    justify-content: space-between !important;
}
.ant-drawer-title {
    font-size: 18px;
    font-weight: normal;
    text-align: right !important;
    justify-content: space-between !important;
    flex: unset !important;
}

.ant-drawer-content,
.ant-drawer-content-wrapper {
    border-top-left-radius: 52px;
    border-top-right-radius: 52px;
    height: max-content !important;
}

.ant-drawer-content,
.ant-drawer-wrapper-body {
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    width: 80%;
    height: max-content !important;

    margin: 0 auto;
    background: rgba(21, 21, 21, 1) !important;
}
.ant-drawer-body {
    width: 80%;
    margin: 0 auto;
    padding: 0px !important;
    height: max-content !important;
    .ns-coins-list {
        padding: 0px !important;
    }
    // padding-bottom: 48px !important;
    margin-bottom: 120px !important;
}
.ant-drawer-title {
    font-weight: 500 !important;
    font-size: 14px !important;
    color: #fff !important;
    text-align: right !important;
    margin-right: 0px !important;
}
.ant-drawer-close span svg {
    fill: #fff !important;
    opacity: 0.4 !important;
    width: 20px !important;
    height: 20px !important;
}

.ant-drawer-header {
    padding: 24px 0px !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05) !important;
    width: 80% !important;
    margin: 0 auto;
}

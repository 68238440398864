@import "src/styles/references/index.scss";

.ns-coin-asset {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 450px;
    .main-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .coin-info {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            img {
                height: 58px;
                margin-bottom: 8px;
            }

            span:nth-child(3) {
                font-size: 8px;
            }
        }

        .coin-actions {
            display: flex;
            justify-content: center;
            align-content: center;
            column-gap: 48px;
            margin-top: 16px;
            margin: 0 auto;
            margin-top: 16px !important;
            svg {
                fill: #f7f7f7 !important;
            }
        }

        .coin-history {
        }
    }
}

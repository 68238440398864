@import "src/styles/references/index.scss";

.ns-narvbar-container {
    position: fixed;
    top: calc(var(--vh, 1vh) * 100);
    width: 100%;
    height: calc(var(--vh, 1vh) * 50);
    background: #212121 !important;

    border-radius: 30px 30px 0px 0px;
    padding-top: 40px;
    box-sizing: border-box;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &:after {
        content: "";
        left: 50%;
        top: -14px;
        width: 45px;
        height: 4px;
        background: rgba(0, 0, 0, 0.2);
        position: absolute;
        transform: translate(-50%);
        border-radius: 20px;
    }

    p {
        width: 70%;
        padding-top: 20px;
        font-size: 0.8rem;
        text-align: center;
        font-weight: 500;
        opacity: 0.7;
    }
    img {
        width: 75%;
    }
    button {
        padding: 12px 30px;
        background: rgb(232, 76, 79);
        color: #fff;
        border: 0px;
        margin-top: 20px;
        border-radius: 17px;
        path {
            stroke: rgba(144, 144, 144, 1) !important;
        }
    }

    &::before {
        content: "";
        left: 0px;
        bottom: -150px;
        width: 100%;
        height: 150px;
        background: #fff;
        position: absolute;
    }
}
.ns-bottom-navbar {
    position: fixed;
    bottom: 0px;
    display: flex;
    z-index: 999999;
    left: 0%;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    background: #212121 !important;
    padding-bottom: 12px;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);

    .menu-item {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        transition: 0.4s;

        .title {
            font-size: 12px !important;
            text-align: center;
            font-weight: 400;
            margin-top: -8px !important;
        }
    }
    button {
        width: 60px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0px;
        background: transparent;
        border-radius: 20px;
        transition: all 0.25s ease;
    }
    button.active {
        path {
            stroke: #ffffff !important;
        }
    }
    span {
        color: rgba(144, 144, 144, 1) !important;
    }
    span.active {
        color: #fff !important;
    }
    button:active:not(.float) {
        transform: scale(1.2);
    }
    button.float {
        margin-top: -50px;
        color: #fff;
        border: 4px;
        background: $primary;
        border-radius: 100px;
        height: 60px;
        transition: 0.4s;
    }

    button svg {
        font-size: 1.5rem;
        pointer-events: none;

        &:hover {
            cursor: pointer;
        }
    }

    .con-effect {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        overflow: hidden;
        pointer-events: none;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .effect {
        background: rgba(232, 76, 79, 0.15);
        width: 60px;
        height: 50px;
        position: absolute;
        left: 13px;
        border-radius: 18px;
    }
}

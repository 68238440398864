@import "src/styles/references/index.scss";

.ns-history {
    width: 100%;

    .transaction-list {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        overflow-x: hidden;
        padding: 8px;
        width: 100%;

        &::-webkit-scrollbar {
            display: none !important;
        }
    }

    .ns-headline {
        svg {
            height: 184px !important;
        }
        h2 {
            text-align: center;
            margin-bottom: 24px;
            font-weight: normal;
            font-size: 18px;
        }
    }
    .transaction-item {
        display: flex;
        height: 87px;
        max-width: 327px;
        width: 100%;
        background: rgba(27, 27, 27, 1) !important;
        justify-content: space-between;
        padding: 0px 18px;
        border-radius: 12px;
        margin: 8px auto;
        flex-direction: row-reverse;
        align-items: center;
        cursor: pointer;

        .right-side {
            display: flex;
            flex-direction: inherit;
            align-items: center;
            justify-content: end;
            text-align: end;
            column-gap: 8px;
            .type {
                span:first-child {
                    color: #ffffff !important;
                    font-size: 12px;
                    font-weight: 700;
                }
                span:nth-child(2) {
                    color: rgba(255, 255, 255, 0.5);
                    font-weight: 300;
                    font-size: 10px;
                }
                display: flex;
                flex-direction: column;
                row-gap: 4px;
            }
            img {
                width: 32px !important;
                height: 32px !important;
            }
            span:nth-child(2) {
                font-size: 14px !important;
            }
        }

        .left-side {
            .amounts {
                display: flex;
                justify-content: center;
                column-gap: 2px;

                flex-direction: column;
                align-items: flex-start;
                &.in {
                    span {
                        color: rgba(0, 213, 85, 1);
                        font-size: 12px;
                        font-weight: 700;
                    }

                    span:nth-child(2) {
                        color: #ffffff !important;
                    }
                }
                &.out {
                    font-size: 12px;
                    font-weight: 700;
                    color: #ffffff;
                    span:nth-child(2) {
                        color: rgba(0, 213, 85, 1);
                        font-size: 12px;
                        font-weight: 700;
                    }
                }
            }
        }
    }
}

.transaction-details-drawer {
    min-height: 340px !important;
    .market-info {
        display: flex;
        align-content: center;
        justify-content: center;
        margin-right: 15%;
        &.single {
            margin-right: 0 !important;
        }
        img {
            height: 64px;
        }
        img:nth-child(2) {
            z-index: 99999;
            position: absolute;
            left: 50%;
        }
    }
    .swap-info {
        display: flex;
        align-content: center;
        flex-direction: column;
        justify-content: center;
        margin-top: 32px;
        .source {
            font-size: 16px;
            color: rgba(255, 255, 255, 0.5);
            font-weight: 600;
        }
        .destination {
            font-size: 16px;
            color: #ffffff;
            font-weight: 600;
            margin-top: 4px;
        }
        .amount {
            color: rgba(255, 255, 255, 0.5);
            font-size: 10px;
            font-weight: 300;
            margin-top: 8px;
        }
        .date {
            color: rgba(255, 255, 255, 0.5);
            font-size: 10px;
            font-weight: 300;
            margin-top: 4px;
            direction: ltr;
        }
    }
    .data-grid-box {
        display: flex;
        max-width: 327px;
        background: rgba(27, 27, 27, 1);
        flex-direction: column;
        padding: 16px 16px;
        margin-top: 32px;
        .data-grid-row {
            width: 100%;
            display: flex;
            width: 100%;
            justify-content: space-between;
            flex-direction: column;
            span.name {
                font-size: 10px;
                text-align: right;
                font-weight: 400;
                color: rgba(255, 255, 255, 0.5);
            }
            span:nth-child(2) {
                font-size: 12px;
                text-align: left;
                font-weight: 400;
                word-break: break-all;
                max-width: 35ch !important;
                color: rgba(255, 255, 255, 1);

                column-gap: 4px;
                svg {
                    font-size: 10px;
                    fill: $primary;
                }
            }
            .commission {
                display: flex;
                flex-direction: column;
                span:first-child {
                    font-weight: 600;
                    font-size: 12px;
                    text-align: left;

                    color: rgba(255, 255, 255, 1);
                }
                span:nth-child(2) {
                    font-size: 10px;
                    text-align: left;
                    font-weight: 300;
                    color: rgba(255, 255, 255, 1);
                }
            }
        }
    }
    .ns-button {
        margin-top: 32px;
        height: 32px;

        width: max-content;
    }
}

@import "src/styles/references/index.scss";

.ns-navbar {
    width: 100%;
    .head-line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
        width: 100%;

        svg {
            cursor: pointer;
        }

        .profile {
            display: flex;
            flex-direction: row;
            column-gap: 4px;
            align-items: center;
            .avatar {
                height: 28px;
                width: 28px;
                .ant-avatar {
                    height: 24px;
                    width: 24px;
                    background: $primary !important;
                    position: relative;
                    span {
                        position: absolute;
                        left: 30%;
                        font-weight: bold;
                        top: -10%;
                    }
                }
            }
            span.name {
                font-size: 10px;
                color: $white;
                opacity: 0.5;
            }
        }
        .today {
            font-size: 10px;
            color: $white;
            opacity: 0.5;
        }
    }
}

@import "src/styles/references/index.scss";

.ns-coins-list {
    width: 100%;
    max-height: 428px;
    overflow-x: hidden;
    padding: 8px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    padding-bottom: 32px;
    &::-webkit-scrollbar {
        display: none !important;
    }
    h5 {
        color: #ffffff;
        font-weight: 300;
        font-size: 14px;
        margin-bottom: 32px;
        opacity: 0.5;
    }
    .coin-item:not(:last-child) {
        border-bottom: 1px solid rgba(255, 255, 255, 0.05);
        padding-bottom: 16px !important;
    }
    .coin-item {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 8px 16px;
        margin: 8px auto;
        flex-direction: row-reverse;
        align-items: center;
        cursor: pointer;

        .right-side {
            display: flex;
            flex-direction: inherit;
            align-items: center;
            width: 124px;
            justify-content: end;
            text-align: end;
            column-gap: 8px;
            img {
                width: 32px !important;
                height: 32px !important;
            }
            .coin-info {
                svg {
                    width: 58px !important;
                    height: 58px !important;
                }
                h4 {
                    font-size: 14px;
                    color: #fff !important;
                    margin-bottom: 3px;
                }
                span {
                    font-size: 12px;
                    color: #fff !important;
                }
                span:nth-child(2) {
                    font-size: 8px;
                    color: #fff !important;

                    span {
                        font-size: 8px;
                        color: #fff !important;

                        &.low {
                            color: $danger !important;
                        }
                        &.high {
                            color: $success !important;
                        }
                    }
                }
            }
        }

        .left-side {
            .balance {
                display: flex;
                align-items: center;
                justify-content: center;
                column-gap: 2px;

                span {
                    font-size: 14px;
                    color: #fff !important;
                }
                span:nth-child(2) {
                    font-size: 8px;
                    color: #fff !important;
                }
            }
        }
    }
}

@import "src/styles/references/index";

.ns-button {
  max-width: 180px;
  width: 100%;
  margin: 0 auto;
  cursor: pointer;

  &.done {
    margin-top: 20px;
  }

  button {
    border: 0;
    outline: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    cursor: pointer;
    span {
      margin: 0 5px;
    }

    &:hover {
      cursor: pointer;
    }

    &.disable {
      opacity: 0.4;
      &.swap {
        opacity: 1 !important;
        background: rgba(63, 18, 26, 1) !important;
      }
    }

    &.success {
      background: $success;
      color: $white;
    }

    &.info {
      background: $waiting-badge;
      color: $warning;
    }

    &.swap {
      background: $primary !important;
      padding: 4px 2px !important;
      span {
        font-size: 10px !important;
        color: #ffffff;
      }
    }
    &.dark {
      background: rgba(27, 27, 27, 1);
      padding: 6px 12px !important;
      span {
        font-size: 10px !important;
        color: rgba(255, 255, 255, 0.5);
        font-weight: 400;
      }
    }

    &.deposit {
      background: $success !important;
      padding: 4px 2px !important;
      span {
        font-size: 10px !important;
      }
    }

    &.widthrawal {
      background: $danger !important;
      padding: 4px 2px !important;
      span {
        font-size: 10px !important;
      }
    }

    &.primary {
      background: $primary;
      color: $white;
    }

    &.secondary {
      background: $secondary;
      color: $white;
    }

    &.error {
      background: $danger;
      color: $white;
    }

    &.info {
      background: $primary;
      color: $white;
    }

    // &.outlined {
    //   background: $white;
    //   border: 1px solid $info !important;
    //   color: $info;
    //   height: 38px;
    //   transition: 0.4s;

    //   &:hover {
    //     background: $primary;
    //     color: $white;
    //     transition: 0.4s;
    //   }
    // }
  }
}

@import "src/styles/references/index.scss";

.ns-signin {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    height: 100vh;

    .signin-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 28px;

        .ns-button {
            width: 100% !important;
            padding: 0px !important;
            margin: 0px !important;
            max-width: unset !important;
            button {
                width: 100% !important;
                height: 38px !important;
                font-size: 14px !important;
                margin: 0px !important;
            }
        }
    }
}

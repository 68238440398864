@import "src/styles/references/index.scss";

.ns-swapbox {
    background: rgba(27, 27, 27, 1);

    border-radius: 12px;
    display: flex;
    flex-direction: row;
    column-gap: 8px;
    align-items: center;
    justify-content: space-between;
    padding: 16px 8px;
    direction: ltr;

    flex: 1 1 45%; /* This makes each item take up to 50% of the container's width */
    box-sizing: border-box;
    padding: 10px; /* Optional: Add some padding for spacing */
    text-align: center; /* Optional: Center text within items */
    max-width: 49%;
    img {
        height: 32px;
        width: 32px;
    }
    span {
        width: 10ch;
        background: linear-gradient(to right, #0291e3, #09e485);
        -webkit-background-clip: text;
        color: white;
        b {
            background-clip: text;
            color: transparent;
        }
    }
}

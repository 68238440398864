@import "src/styles/references/index.scss";

.ns-empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    max-width: 327px;
    margin-top: 64px;
    p {
        color: #ffffff;
        font-size: 12px;
        direction: rtl;
        text-align: center;
        font-weight: 300;
    }
}

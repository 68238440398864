@import "src/styles/references/index.scss";

.ns-box {
    width: max-content;
    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: column;
    height: max-content;

    .box-content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        svg {
            background: #ffffff;
            height: 32px;
            width: 32px;
            border-radius: 50%;
            padding: 8px;
            cursor: pointer !important;
            margin: 0 !important;
        }
        span {
            margin-top: 6px !important;
            svg {
                background: #ffffff;
                fill: #151515 !important;
            }
        }
    }

    span {
        color: #ffffff;
        font-size: 12px;
        cursor: pointer !important;
    }
}

@mixin rtl {
    @at-root body[dir="rtl"] & {
        @content;
    }
}

@mixin ns-xs {
    @media (max-width: 575.98px) {
        @content;
    }
}

@mixin ns-sm {
    @media (min-width: 575.98px) {
        @content;
    }
}

@mixin ns-md {
    @media (min-width: 767.98px) {
        @content;
    }
}

@mixin ns-lg {
    @media (min-width: 991.98px) {
        @content;
    }
}

@mixin ns-xl {
    @media (min-width: 1199.98px) {
        @content;
    }
}
@mixin ns-pre-xs {
    @media (max-width: 575.98px) {
        @content;
    }
}

@mixin ns-pre-sm {
    @media (max-width: 575.98px) {
        @content;
    }
}

@mixin ns-pre-md {
    @media (max-width: 767.98px) {
        @content;
    }
}

@mixin ns-pre-lg {
    @media (max-width: 991.98px) {
        @content;
    }
}

@mixin ns-pre-xl {
    @media (max-width: 1199.98px) {
        @content;
    }
}

@mixin ns-size($property, $web-value, $mobile-value, $important: null) {
    @include ns-xs {
        #{$property}: $mobile-value $important;
    }
    @include ns-sm {
        #{$property}: (2 * $mobile-value + $web-value)/3 $important;
    }
    @include ns-md {
        #{$property}: ($mobile-value + $web-value)/2 $important;
    }
    @include ns-lg {
        #{$property}: ($mobile-value + 2 * $web-value)/3 $important;
    }
    @include ns-xl {
        #{$property}: $web-value $important;
    }
}

@mixin rtl {
    @at-root body[dir="rtl"] & {
        @content;
    }
}

@mixin ltr {
    @at-root body[dir="ltr"] & {
        @content;
    }
}

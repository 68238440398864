@import "src/styles/references/index.scss";

.ns-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: right;

    .connected-exchanges {
        h4 {
            color: #ffffff;
            margin-top: 32px;
            margin-bottom: 24px;
        }
        .grid-row {
            display: flex;
            justify-content: center;
            align-items: center;
            column-gap: 16px;
            text-align: center;
            flex-direction: row-reverse;
        }
        img {
            height: 56px;
            width: 56px;
            border-radius: 16px;
        }
        span {
            font-weight: 300;
            font-size: 10px;
            margin-top: 6px;
            color: rgba(250, 250, 250, 0.8);
            text-align: center;
        }
    }
    .data-grid-box {
        display: flex;
        max-width: 327px;
        background: rgba(27, 27, 27, 1);
        flex-direction: column;
        padding: 16px 16px;
        display: flex;
        flex-direction: column;
        border-radius: 12px;
        margin-top: 32px;
        width: 327px;
        .data-grid-row:first-child,
        .data-grid-row:nth-child(2) {
            border-bottom: 1px solid rgba(255, 255, 255, 0.05);
            padding-bottom: 8px;
        }
        .data-grid-row:nth-child(2),
        .data-grid-row:nth-child(3) {
            padding-top: 8px;
        }

        .data-grid-row {
            width: 100%;
            display: flex;
            width: 100%;
            justify-content: space-between;
            flex-direction: column;
            span.name {
                font-size: 10px;
                text-align: right;
                font-weight: 400;
                color: rgba(255, 255, 255, 0.5);
            }
            span:nth-child(2) {
                font-size: 12px;
                text-align: left;
                font-weight: 400;
                color: rgba(255, 255, 255, 1);
            }
            .commission {
                display: flex;
                flex-direction: column;
                span:first-child {
                    font-weight: 600;
                    font-size: 12px;
                    text-align: left;

                    color: rgba(255, 255, 255, 1);
                }
                span:nth-child(2) {
                    font-size: 10px;
                    text-align: left;
                    font-weight: 300;
                    color: rgba(255, 255, 255, 1);
                }
            }
        }
    }

    .profile-menu {
        width: 95%;
        ul li {
            margin-bottom: 16px;
            border: 0.5px solid #121212;
            padding: 18px 14px;
            border: 0.5px solid #121212;
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            justify-content: space-between;
            transition: 0.3s;
            &:hover {
                cursor: pointer;
                background: #121212;
                transition: 0.3s;

                color: #ffffff;
                svg {
                    fill: #ffffff;
                }
            }
        }
    }
}

.update-email-drawer {
    .input-field {
        background: rgb(27, 27, 27) !important;
        border: unset !important;
        margin-bottom: 23px;
    }
    input {
        background: rgb(27, 27, 27) !important;
        color: #ffffff;
        opacity: 0.6;
    }

    .ns-button {
        max-width: 120px;
        margin-left: 0px;
        button {
            height: 38px;
        }
    }
}

@import "src/styles/references/index.scss";

.ns-container {
    display: flex;
    // background: $bg;
    width: 100%;
    min-height: 100vh;
    height: 450px;
    overflow-y: auto;
    margin: auto;
    column-gap: 32px;
    scrollbar-width: none;
    margin-bottom: 124px;
    ::-webkit-scrollbar {
        width: 0px !important;
    }

    ::-webkit-scrollbar-thumb {
        background: $scroll-thumb-bg;
    }

    ::-webkit-scrollbar-track {
        background: $white;
    }

    .swap-boxes {
        display: flex;
        flex-wrap: wrap;
        column-gap: 8px;
        row-gap: 8px;
        direction: rtl;
    }
}

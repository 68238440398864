@import "src/styles/references/index.scss";

.swiper-button {
    position: relative;
    width: 100%;
    max-width: 327px;
    height: 48px;
    background: $disabled-primary;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: start;
    overflow: hidden;
    margin: 32px auto;
    svg {
        margin-left: 55%;
    }

    &.checked {
        width: unset !important;
        max-width: max-content !important;
        padding: 16px !important;
        border-radius: 50%;
        background: $primary;
        svg {
            margin-left: unset;
        }
    }

    :where(.css-dev-only-do-not-override-12upa3x).ant-spin .ant-spin-dot-item {
        background: #ffffff !important;
    }
}

.swiper-thumb {
    position: absolute;
    width: 162px;
    height: 48px;
    background: $primary;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    touch-action: none;
    user-select: none;
    text-align: center !important;
}

.swiper-text {
    z-index: 1;
    font-weight: 500;
    font-size: 12px;
    color: #ffffff;
}

@import "src/styles/references/index.scss";

.ns-headline {
    width: max-content !important;
    margin-right: 0px;
    margin-left: auto;
    h2 {
        color: #fff;
        text-align: end;
        display: flex;
        align-content: center;
        flex-direction: row-reverse;
        align-content: center;
        align-items: center;
        column-gap: 6px;
        margin: 18px 0px;
        max-height: 24px;
    }

    svg {
        margin-top: 4px !important;
    }
}

@import "src/styles/references/index.scss";

.ns-dashboard {
    margin: auto auto;
    width: 95vw;
    min-height: max-content;
    // max-height: 1080px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 0px;
    scrollbar-width: none;
    padding-bottom: 165px;

    @include ns-pre-lg {
        scrollbar-width: 0 !important;
        max-height: unset;
        overflow-y: visible;
        overflow-x: hidden;
    }
    ::-webkit-scrollbar {
        width: 0px !important;
    }

    ::-webkit-scrollbar-thumb {
        background: $scroll-thumb-bg;
    }

    ::-webkit-scrollbar-track {
        background: $white;
    }
}
